<template>
  <div>

    <v-container style="margin-bottom: 50px">
      <Carousel1 style="padding-top: 70px"></Carousel1>

      <div v-html="getTranslate(intro)"></div>
      <h1 style="font-weight: bold">{{ $t('finePlantYouWillLove') }}</h1>
      <br>
      <div class="justify-content-center">
        <div class="section_tittle text-center">
          <h2></h2>
        </div>
      </div>

      <v-row>
        <v-col
            v-for="(d,i) in typeList"
            :key="i"
            class="d-flex child-flex"
            cols="6"
            md="3"
            @click="goToPlant(d)"
            style="cursor: pointer;position: relative;margin-top: 30px;margin-bottom: 30px"
            sm="3"
        >
          <div>
            <img class="img-circle" v-if="!!d.urlList" style="margin-top: 20px" :src="d.urlList[0]" height="180"
                 width="180"/>
          </div>

          <div class="changeColor centered" style="font-weight: bold;font-size: 14px;padding-top: 40px"
               v-html="getTranslate(d.title)"></div>

        </v-col>
      </v-row>
    </v-container>
    <section>
      <v-btn class="ma-2 pl-16 pr-16 pt-5 pb-5"
             outlined
             @click="goToAllPlant()"
             large
             color="green" style="font-weight: bold;font-size: 26px">{{ $t('seeAllPlant') }}
      </v-btn>
    </section>
    <h1 style="font-weight: bold">{{ $t('topTrendingPlants') }}</h1>
    <br>
    <div class="row justify-content-center">
      <div class="section_tittle text-center">
        <h2></h2>
      </div>
    </div>
    <v-slide-group
        v-model="model"
        center-active
        class="pa-4"
        active-class="success"
        show-arrows
    >
      <v-slide-item
          v-for="(d,n) in topTrendingPlant"
          :key="n"
          v-slot="{ active, toggle }"

      >

        <v-card
            :class="active ? 'ma-4 imgZoomActive' : 'ma-4'"
            height="310"
            width="192"
            @click="toggle"

        >
          <v-img
              :src="d.url || d.urlList[0]"
              height="190px"
              @click="gotToPlantDetail(d)"
          ></v-img>
          <v-card-subtitle style="color: green;font-weight: bold;font-size: 20px;" @click="gotToPlantDetail(d)"
          >
            {{ d.currency | getCurrency }}{{ d.minPrice }} - {{ d.currency | getCurrency }}{{ d.maxPrice }}
          </v-card-subtitle>

          <v-card-subtitle @click="gotToPlantDetail(d)" style="padding-top: 0px !important;font-size: 15px;font-weight: bold">
            <div v-html="getTranslate(d.title)"></div>
          </v-card-subtitle>


        </v-card>
      </v-slide-item>
    </v-slide-group>

    <!--    <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.0975485589447!2d103.22064420445466!3d13.08681856835123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3105497596886179%3A0x59fceb57aa60f66c!2z4Z6c4Z634Z6R4Z-S4Z6Z4Z624Z6f4Z-S4Z6Q4Z624Z6T4Z6U4Z6F4Z-S4Z6F4Z-B4Z6A4Z6c4Z634Z6R4Z-S4Z6Z4Z624Z6U4Z624Z6P4Z-L4Z6K4Z-G4Z6U4Z6E!5e0!3m2!1skm!2skh!4v1658996488191!5m2!1skm!2skh"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>-->
    <div style="margin-bottom: 50px"></div>
    <v-container>
      <section>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-card
                class="mx-auto"
            >
              <div style="justify-content: center">
                <h2 style="font-weight: bold">{{ $t('shopByCare') }}</h2>
              </div>
              <div class="justify-content-center">
                <div class="section_tittle text-center">
                  <h2></h2>
                </div>
              </div>
              <br>
              <v-card-title>
                <v-row>
                  <v-col cols="12" sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('Easy','Care')"

                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.46.52.png" height="102" width="108"/>
                      <v-card-subtitle>
                        <h2>{{ $t('easy') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12"  sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('Moderate','Care')"

                    >
                      <br>

                      <img src="../assets/photo/photo_2022-10-06 20.46.20.png" height="102" width="108"/>
                      <v-card-subtitle>
                        <h2>{{ $t('moderate') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('Advanced','Care')"
                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.46.39.png" height="102" width="108"/>
                      <v-card-subtitle>
                        <h2>{{ $t('advanced') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card
                class="mx-auto"
            >
              <div style="justify-content: center">
                <h2 style="font-weight: bold">{{ $t('shopBySize') }}</h2>
              </div>
              <div class="justify-content-center">
                <div class="section_tittle text-center">
                  <h2></h2>
                </div>
              </div>
              <br>
              <v-card-title>
                <v-row>
                  <v-col cols="12"  sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('small','Size')"

                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.45.00.png"  height="102" width="108"/>

                      <v-card-subtitle>
                        <h2>{{ $t('small') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12"   sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('medium','Size')"
                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.45.21.png"  height="102" width="108"/>

                      <v-card-subtitle>
                        <h2>{{ $t('medium') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12"   sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('large','Size')"

                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.43.13.png" height="102" width="108"/>

                      <v-card-subtitle>
                        <h2>{{ $t('large') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card
                class="mx-auto"

            >
              <div style="justify-content: center">
                <h2 style="font-weight: bold">{{ $t('shopByLight') }}</h2>
              </div>
              <div class="justify-content-center">
                <div class="section_tittle text-center">
                  <h2></h2>
                </div>
              </div>
              <br>
              <v-card-title>
                <v-row>
                  <v-col cols="12"  sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('lowLight','Light')"

                    >
                      <br>
                      <img
                        src="../assets/photo/photo_2022-10-06 20.45.36.png" height="102" width="108"/>
                      <v-card-subtitle>
                        <h2>{{ $t('lowLight') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12"  sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('indirectLight','Light')"

                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.46.04.png" height="102" width="108"/>

                      <v-card-subtitle>
                        <h2>{{ $t('indirectLight') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12"  sm="4" class="changeOnHover">
                    <v-card
                        class="mx-auto"
                        max-width="344"
                        @click="goToAllPlant('directLight','Light')"
                    >
                      <br>
                      <img src="../assets/photo/photo_2022-10-06 20.45.50.png" height="102" width="108"/>

                      <v-card-subtitle>
                        <h2>{{ $t('directLight') }}</h2>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-container>

    <section style="margin-bottom: 0px;padding: 0px">
      <div style="justify-content: center">
        <h1 style="font-size: 50px;font-weight: bold">{{ $t('latestPlant') }}</h1>
      </div>

      <div class="justify-content-center">
        <div class="section_tittle text-center">
          <h2></h2>
        </div>
      </div>
    </section>
    <v-row>
      <v-col
          v-for="(d,i) in latestProductList"
          :key="i"
          class="d-flex child-flex"
          cols="6"
          @click="gotToPlantDetail(d)"
          md="2"
          style="cursor: pointer;position: relative;margin-bottom: 80px"
          sm="2"
      >
        <div class="imgZoom">
          <v-img v-if="!!d.url || !!d.urlList" style="margin-top: 20px" :src="d.url || d.urlList[0]"  aspect-ratio="1"/>
        </div>
        <div class="changeColor centered">
          <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              style="font-size: 25px"
          >
            {{ d.currency | getCurrency }}{{ d.minPrice }} - {{ d.currency | getCurrency }}{{ d.maxPrice }}
          </v-chip>
        </div>
        <div class="changeColor centeredTitle" style="font-weight: bold;font-size: 14px;"
             v-html="getTranslate(d.title)"></div>

      </v-col>
    </v-row>
    <v-container>
      <div>
        <CoolLightBox
            :items="gallaryList"
            :index="index"
            @close="index = null">
        </CoolLightBox>
      </div>
    </v-container>
    <section style="margin-bottom: 50px">
      <div style="justify-content: center">
        <h1 style="font-size: 50px;font-weight: bold">{{ $t('gallery') }}</h1>
      </div>
      <div class="justify-content-center">
        <div class="container">
          <h2></h2>
          <br>
          <v-row>
            <v-col
                v-for="(n,i) in gallaryList"
                :key="i"
                class="d-flex child-flex imgUp"
                cols="2"
                md="3"
                style="cursor: pointer"
                sm="3"
            >
              <v-img
                  :src="n"
                  @click="index = i"
                  aspect-ratio="1"
                  class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </div>
      </div>
    </section>
    <div v-if="!$vuetify.breakpoint.mobile" class="elfsight-app-b25d5e88-bf10-49f1-80f9-6b0444703dad"></div>

    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1037.2010336257629!2d103.19426358716215!3d13.11415648746183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31054bb936fbebf5%3A0x49ceab764fc6c66d!2zR3JlZW4gSG91c2UgLSDhnpXhn5LhnpHhn4fhnpThn4Phno_hnoQ!5e0!3m2!1skm!2skh!4v1660361372575!5m2!1skm!2skh"
        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>

</template>

<script>

import MyMixin from "@/mixins/mixin"
import Carousel1 from "@/views/layout/carousel1";

import axios from "axios";
import {Constants} from "../libs/constant";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "Home",
  mixins: [MyMixin],
  components: {CoolLightBox,Carousel1},
  data() {

    return {
      index: null,
      typeList: [],
      topTrendingPlant: [],
      latestProductList: [],
      gallaryList: [],
      model: null,
      intro:""
    };
  },
  mounted() {
    this.onResize();
  },
  watch: {
    videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
            "https://www.youtube.com/embed/" +
            val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
    goToPlant(d, filter) {
      //:to="{name:'plant',params:{title:getTranslate(d.title),id:d._id,type:d.type,doc:d}}"
      let vm = this;
      vm.$router.push({
        name: 'plant',
        params: {title: vm.getTranslate(d.title), id: d._id, type: d.type, doc: d, filter: filter}
      });
    },
    goToAllPlant(filter, type) {
      //:to="{name:'plant',params:{title:getTranslate(d.title),id:d._id,type:d.type,doc:d}}"
      let vm = this;
      vm.$router.push({
        name: 'allPlant',
        params: {title: "All Plant", filter: filter, type: type}
      });
    },
    gotToPlantDetail(d) {
      //:to="{name:'plant',params:{title:getTranslate(d.title),id:d._id,type:d.type,doc:d}}"
      let vm = this;
      vm.$router.push({name: 'plantDetail', params: {title: vm.getTranslate(d.title), id: d._id, doc: d}});
    },
    fetchTab() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getTab;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          addToHome: false
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.typeList.push(...res.data.data.plantType.filter(o => o.addToHome === true));
                vm.typeList.push(...res.data.data.plantRoom.filter(o => o.addToHome === true));
                vm.typeList.push(...res.data.data.plantLifeStyle.filter(o => o.addToHome === true));
                vm.typeList.push(...res.data.data.plantGift.filter(o => o.addToHome === true));
              }
            },
            error => {
              reject(error);
            }
        );
      });

    },
    fetchProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProduct;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          params: {}
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.topTrendingPlant = res.data.data.slice(0, 20);
                vm.latestProductList = res.data.data.slice(0, 42);

              }
            },
            error => {
              reject(error);
            }
        );
      });

    },
    fetchTopTrendProduct() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.topTrendProduct;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.topTrendingPlant = res.data.data;

              }
            },
            error => {
              reject(error);
            }
        );
      });

    },
    fetchHome() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHome;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          addToHome: false
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.gallaryList = res.data.data && res.data.data.slideImgList || [];
                vm.intro=res.data.data.intro || "";
              }
            },
            error => {
              reject(error);
            }
        );
      });

    }
  },
  created() {
    this.fetchTab();
    this.fetchProduct();
    this.fetchHome();
    this.fetchTopTrendProduct();
  },
};
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.member_counter {
  padding: 73px 0px 73px;
  background-image: -webkit-linear-gradient(0deg, lightblue 0%, #244a93 100%);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}

.program :hover {
  elevation: 30deg;
  color: darkgoldenrod;
}

.imgZoom :hover {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoomActive {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoom1 :hover {
  transition: transform .20s;
  transform: scale(1.05);
}

.imgUp :hover {
  transition: transform .2s;
  margin-top: -10px;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);

}
.changeOnHover :hover {
  background: lightgreen;
  color: white;
}

.img-circle {
  border-radius: 50%;
}

.row {
  margin: 0px !important;
}

</style>
